import {
  ASIDE_TYPE_CLOSE,
  ASIDE_PARAM_ID,
  ASIDE_PARAM_TYPE,
  ASIDE_PARAM_VIEW,
  type AsideType,
  ASIDE_PARAM_OPTIONS,
  type AsideOption,
} from "@/app/_components/Aside/utils/aside"
import type { Route } from "next"
import { usePathname, useRouter, useSearchParams } from "next/navigation"
import { useCallback, useEffect, useMemo, useReducer } from "react"

export default function useAside() {
  const pathname = usePathname()
  const router = useRouter()
  const searchParams = useSearchParams()

  interface OptimisticState {
    pathname: string
    searchParams: URLSearchParams
  }

  const [optimisticState, dispatch] = useReducer(
    (state: OptimisticState, action: Partial<OptimisticState>) => ({
      ...state,
      ...action,
    }),
    {
      pathname,
      searchParams,
    },
  )

  const asideType: AsideType = optimisticState.searchParams?.get(
    ASIDE_PARAM_TYPE,
  ) as AsideType
  const asideId = optimisticState.searchParams?.get(ASIDE_PARAM_ID) ?? ""
  const asideView = optimisticState.searchParams?.get(ASIDE_PARAM_VIEW) ?? ""

  const createAsideSearch = useCallback(
    (type: AsideType, id?: string, asideView?: string) => {
      const newSearchParams = new URLSearchParams(
        optimisticState.searchParams?.toString(),
      )

      newSearchParams?.set(ASIDE_PARAM_TYPE, type)
      if (id) {
        newSearchParams?.set(ASIDE_PARAM_ID, id)
      } else {
        newSearchParams?.delete(ASIDE_PARAM_ID)
      }
      if (asideView) {
        newSearchParams?.set(ASIDE_PARAM_VIEW, asideView)
      } else {
        newSearchParams?.delete(ASIDE_PARAM_VIEW)
      }
      newSearchParams?.delete(ASIDE_PARAM_OPTIONS)

      dispatch({
        searchParams: newSearchParams,
      })
      return newSearchParams?.toString()
    },
    [optimisticState.searchParams],
  )

  const openAside = useCallback(
    (type: AsideType, id?: string, asideView?: string) => {
      const newSearchParams = new URLSearchParams(
        optimisticState.searchParams?.toString(),
      )

      newSearchParams?.set(ASIDE_PARAM_TYPE, type)
      if (id) {
        newSearchParams?.set(ASIDE_PARAM_ID, id)
      } else {
        newSearchParams?.delete(ASIDE_PARAM_ID)
      }
      if (asideView) {
        newSearchParams?.set(ASIDE_PARAM_VIEW, asideView)
      } else {
        newSearchParams?.delete(ASIDE_PARAM_VIEW)
      }
      newSearchParams?.delete(ASIDE_PARAM_OPTIONS)

      dispatch({
        searchParams: newSearchParams,
      })
      router.push(
        `${optimisticState.pathname}?${newSearchParams?.toString()}` as Route,
        { scroll: false },
      )
    },
    [optimisticState.pathname, optimisticState.searchParams, router],
  )

  const closeAside = useCallback(
    ({
      keepMounted,
    }: {
      keepMounted?: boolean
    } = {}) => {
      const newSearchParams = new URLSearchParams(
        optimisticState.searchParams?.toString(),
      )

      if (keepMounted) {
        newSearchParams?.set(ASIDE_PARAM_OPTIONS, "hideAndKeepMounted")
      } else {
        newSearchParams?.set(ASIDE_PARAM_TYPE, ASIDE_TYPE_CLOSE)
        newSearchParams?.delete(ASIDE_PARAM_ID)
        newSearchParams?.delete(ASIDE_PARAM_VIEW)
        newSearchParams?.delete(ASIDE_PARAM_OPTIONS)
      }

      dispatch({
        searchParams: newSearchParams,
      })
      router.push(
        `${optimisticState.pathname}?${newSearchParams?.toString()}` as Route,
      )
    },
    [optimisticState.pathname, optimisticState.searchParams, router],
  )

  const isAsideOpen = useMemo(
    () => Boolean(asideType) && asideType !== ASIDE_TYPE_CLOSE,
    [asideType],
  )

  const asideSearch = useMemo((): string => {
    return optimisticState.searchParams?.toString()
  }, [optimisticState.searchParams])

  const asideOptions = useMemo(() => {
    const options = optimisticState.searchParams?.get(ASIDE_PARAM_OPTIONS)
    if (options) {
      return new Set(options.split(",") as AsideOption[])
    }
    return new Set()
  }, [optimisticState.searchParams])

  useEffect(() => {
    dispatch({
      pathname,
      searchParams,
    })
  }, [pathname, searchParams])

  return {
    asideSearch,
    asideType,
    asideId,
    asideView,
    asideOptions,
    isAsideOpen,
    openAside,
    closeAside,
    createAsideSearch,
  }
}
